/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [{
    url: '/admin/dashboard',
    name: "Dashboard",
    slug: "dashboard",
    icon: "MonitorIcon",
    i18n: "Dashboard"
  },
  {
    url: "/admin/user-management",
    name: "User Management",
    slug: "user-management",
    icon: "UsersIcon",
    i18n: "User Management",
    submenu: [
      {
        url: '/admin/user-management',
        name: "AppUsers",
        slug: "app-user-management",
        i18n: "App Users"
      }
    ]
  },
  {
    url: "/admin/cms-pages",
    name: "Page Management",
    slug: "page-management",
    icon: "FileIcon",
    i18n: "Page Management",
  },
  {
    url: "/admin/email-template",
    name: "Email Templates",
    slug: "email-templates",
    icon: "MailIcon",
    i18n: "Email Templates",
  },
  {
    url: "/admin/recommendations",
    name: "Recommendations",
    slug: "recommendations",
    icon: "SettingsIcon",
    i18n: "Recommendations",
  }
]
